@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@400;500;600;700&family=Libre+Baskerville:ital@1&family=Pacifico&display=swap');
*{
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}
html{
    scroll-behavior: smooth;
}
body{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    height: 100%;
    background: #f1f1f1;
    overflow-x: hidden;
}

/*************************************************** NAVBAR Section *********************************************************/
nav{
    background: #c42727;
    /* background: transparent; */
    height: 80px;
    width: 100%;
    position: fixed;
    z-index: 100;
}
label.logo{
    color: white;
    font-size: 35px;
    line-height: 80px;
    padding: 0 100px;
    font-weight: bold;
}
nav ul{
    float: right;
    margin-right: 20px;
}
nav ul li{
    display: inline-block;
    line-height: 80px;
    margin: 0 5px;
}
nav ul li a{
    color: white;
    font-size: 17px;
    padding:  7px 13px;
    border-radius:  3px;
    text-transform: uppercase;
    text-decoration: none;
}
a.active,a:hover{
    color: #19d03e;
    transition:  .5s;
}
.checkbtn{
    font-size: 30px;
    color: white;
    float: right;
    line-height: 80px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
}
#check{
    display: none;
}
@media (max-width: 952px){ 
    label .logo{
        font-size: 30px;
        padding-left: 50px;
    }
    nav ul li a{
        font-size: 16px;
    }
    nav ul{
        z-index: 100;
    }
}

@media (max-width: 858px){
    .checkbtn{
        display: block;
    }
    nav ul{
        position: fixed;
        width: 100%;
        height: 100vh;
        background: #2c3e50;
        top: 80px;
        left: -100%;
        text-align: center;
        transition: all .5s;
    }
    nav ul{
        z-index: 100;
    }
    nav ul li{
        display: block;
        margin: 50px 0;
        line-height: 30px;
    }
    nav ul li a{
        font-size: 20px;
    }
    a:hover,a.active{
        background: none;
        color: #e60800;
    }
    nav #check:checked ~ ul{
        left: 0;
    }
}
@media screen and (max-width:400px){
    label.logo {
        color: white;
        font-size: 35px;
        line-height: 80px;
        padding: 0px 30px;
        font-weight: bold;
    }
}

/***************************************************** Banner Section ******************************************************/
.banner{
    position: relative;
    background-image: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,0.5)) ,url('./images/banner1.webp');
    background-repeat: no-repeat; 
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 600px;
    overflow: hidden;
}
.banner .container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
}
.banner .container h1{
    margin: 0;
    font-size: 66px;
}
.banner .container h1 span{
    font-size: 100px;
    padding: 6px 14px;
    display: inline-block;
}
.banner .cen{
    margin: 20px;
    display: block;
    font-size: 22px;
}
.banner .button{
    color: #313131;
    padding: 10px 24px;
    background: #f1f1f1;
    text-decoration: none;
    border-radius: 12px;
    transition: .6s;
}
.banner .button:hover{
    opacity: .6;
}
@media screen and (max-width:600px) {
    .banner .container h1 {
        font-size: 30px;
    }
}

/******************************************************* ABOUT Section *******************************************************/
.about-section{
    background-color: rgb(225, 229, 234);
    width: 100%;
    height: 70vh;
    position: relative;
    top: 0px;
}
.about-section .container{
    width: 80%;
    display: block;
    margin: auto;
    padding-top: 70px;
}
.about-section .content-section{
    float: left;
    width: 55%;
}
.about-section .image-section{
    float: right;
    width: 40%;
}
.about-section img{
    width: 100%;
    height: 100%;
}
.about-section h1{
    font-family: 'Libre Baskerville', serif;
    text-transform: uppercase;
    font-size: 28px;
}
.about-section .content h3{
    margin-top: 20px;
    color: rgb(53, 57, 53);
}
.about-section .content p{
    margin-top: 10px;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 1.5;
}
.about-section .content .button{
    margin-top: 30px;
}
.about-section .content .button a{
    background-color: #cf3939;
    color:#f1f1f1;
    padding: 12px 40px;
    text-decoration: none;
    font-size: 25px;
    letter-spacing: 1.5px;
}
.about-section .content .button a:hover{
    background-color: #dd0f0f;
    color: #fff;
}
.about-section .content-section .social{
    margin: 40px;
}
.about-section .content-section .social i{
    color: #a52a2a;
    font-size: 30px;
    padding: 0px 10px;
}
.about-section .content-section .social i:hover{
    color: #3d3d3d;
}

@media screen and (max-width: 768px) {
    .about-section .container{
        width: 80%;
        display: block;
        margin: auto;
        padding-top: 50px;
    }
    .about-section .content-section{
        float: none;
        width: 100%;
        display: block;
        margin: auto;
    }
    .about-section .image-section{
        float: none;
        width: 100%;
    }
    .about-section .image-section img{
        width: 100%;
        height: auto;
        display: block;
        margin: auto;
    }
    .about-section h1{
        text-align: center;
        font-size: 40px;
    }
    .about-section .content .button{
        text-align: center;
    }
    .about-section .content .button a{
        padding: 9px 30px;
    }
    .about-section .content-section .social{
        text-align: center;
    }
    .footer .container{
        grid-template-columns: repeat(1,1fr);
    }
}
@media screen and (max-width:1200px){
    .about-section{
        min-height: 80vh;
    }
}
@media screen and (max-width:950px){
    .about-section{
        min-height: 50vh;
    }
    .about-section img{
        width: 100%;
        height: 80%;
    }
}
@media screen and (max-width:600px) {
    .about-section{
        min-height: 180vh;
    }
}
@media screen and (max-width:560px) {
    .about-section{
        min-height: 80vh;
    }
}
@media screen and (max-width:420px) {
    .about-section{
        min-height: 80vh;
    }
}

/********************** cars Section **********************************/
.cars{
    position: relative;
    top: 7
    0px;
}
.cars .container{
    width: 100%;
    min-height: 100vh;
    background-color: #f7f7f7;
}
.cars .container .car-section{
    width: 80%;
    margin: 0px auto;
}
.cars .container .car-section .heading{
    width: 60%;
    text-align: center;
    margin: 0px auto;
}
.cars .container .car-section .heading h1{
    padding-top: 0px;
    padding-left: 100px;
    position: absolute;
    margin-top: 20px;
    margin-left: 170px;
    font-size: 45px;
    color: #c22f27;
    /* border: 1px solid red; */
}
.cars .container .car-section .heading p{
    margin-top: 10px;
    color: #6e6e6e;
    font-size: 17px;
    line-height: 1.5;
}
.cars .container .car-section .content{
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0px auto; */
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 3px;
}
.cars .container .car-section .content .card{
    flex: 1;
    margin: 30px 15px;
    box-shadow: 2px 10px 2px 2px rgba(245, 66, 66, 0.2);
}
.cars .container .car-section .content .card img{
    width: 100%;
    height: auto;
}
.cars .container .car-section .content .card .text-title{
    font-size: 15px;
    color: #000;
    font-weight: 600;
    margin: 5px 10px;
}
.cars .container .car-section .content .card span{
    color: #6e6e6e;
    font-weight: normal;
}
.cars .container .car-section .content .card h4{
    margin: 5px 10px;
    font-size: 16px;
    color: #bb3434;
}
.cars .container .car-section .content .card p{
    font-size: 14px;
    margin: 5px 10px;
    line-height: 1.5;
    color: #4f4f4f;
}
.cars .container .car-section .content .card a{
    text-decoration: none;
    font-size: 17px;
    display: inline-block;
    padding: 6px 10px;
    color: #fff;
    background-color: #c62f2f;
    margin: 15px 10px;
    border-radius: 5px;
}
.cars .container .car-section .content .card a:hover{
    background-color: #b72d2d;
    transition: 0.4s;
}
@media screen and (max-width:980px) {
    .cars .container .car-section .content{
        /* flex-direction: column; */
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width:580px) {
    .cars .container .car-section .content{
        /* flex-direction: column; */
        grid-template-columns: repeat(1,1fr);
    }
}

/*********************** FOOTER SECTION ****************************/
footer{
    /* display: grid;
    justify-content: end;
    align-content: end; */
    min-height: 10vh;
}
.footer{
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px 100px;
    background-color: #840a0a;
}
.footer .container{
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-gap: 20px;
}
.footer .container h3{
    position: relative;
    color: #fff;
    font-weight: 500;
    margin-bottom: 15px;
}
.footer .container h3::before{
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 50px;
    height: 2px;
    background: #3bff3b;
}
.footer .about p{
    color: #fff;
}
.footer .icons{
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4,50px);
    grid-gap: 10px;
}
.footer .icons i{
    color: #fff;
}
.footer ul{
    padding-left: 0px;
}
.footer .icons li a{
    display: inline-block;
    width: 48px;
    height: 48px;
    background: #d02b19;
    display: grid;
    align-content: center;
    justify-content: center;
    text-decoration: none;
}
.footer .icons li a:hover{
    background: #fe6363;
}
.footer .quicklinks{
    position: relative;
}
.footer .quicklinks li{
    list-style: none;
}
.footer .quicklinks li a{
    color: #f7f7f7;
    text-decoration: none;
    margin-bottom: 10px;
    display: inline-block;
}
.footer .quicklinks li a:hover{
    color: #65cc49;
    padding-left: 2px;
}
.footer .footercontact{
    position: relative;
}
.footer .footercontact li{
    display: grid;
    grid-template-columns: 30px 1fr;
    margin-bottom: 16px;
}
.footer .footercontact li span:nth-child(1){
    color: #fff;
    font-size: 20px;
}
.footer .footercontact span{
    color: #fff;
}
.footer .footercontact a{
    color: #f7f7f7;
    text-decoration: none;
}
.footer .footercontact a:hover{
    color: #52ff3b;
}
@media(max-width:991px){
    .footer{
        padding: 40px;
    }
    
    .footer .container{
        grid-template-columns: repeat(2,1fr);
    }
}
@media(max-width:600px){
    .footer{
        padding: 40px;
    }
    
    .footer .container{
        grid-template-columns: repeat(1,1fr);
    }
}

/**************** Login Page *********************/
.login-container{
    background-image: url(./images/car-3.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100%;
}
.loginbox{
    width: 320px;
    height: 450px;
    background: rgba(181, 15, 15, 0.733);
    color: rgb(230, 222, 222);
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: 70px;
}
.loginbox p{
    font-size: 20px;
}
.loginbox input{
    width: 100%;
    margin-bottom: 20px;
}
.loginbox input[type="text"], input[type="password"]
{
    border: none;
    border-bottom: 1px solid #fff;
    background: transparent;
    outline: none;
    height: 40px;
    color: #fff;
    font-size: 16px;
}
.loginbox input[type="submit"]
{
    border: none;
    outline: none;
    height: 40px;
    background: #fb2525a0;
    color: #fff;
    font-size: 18px;
    border-radius: 20px;
}
.loginbox input[type="submit"]:hover
{
    cursor: pointer;
    background: rgba(218, 32, 32, 0.733);
    color: #000;
}
.loginbox a{
    text-decoration: none;
    font-size: 15px;
    line-height: 10px;
    color: rgb(255, 196, 196);
}
.loginbox a:hover{
    color: rgb(243, 70, 64);
}

/*********************** Register Page ****************/
.register-container{
    background-image: url(./images/car-3.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100%;
}
.register {
    height: 450px;
    width: 320px;
    background: rgba(181, 15, 15, 0.733);
    color: #ffffff;
    top: 55%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    box-sizing: content-box;
    padding: 70px;
}

.register p {
    font-size: 20px;
}

.register input {
    width: 100%;
    margin-bottom: 20px;
}

.register input[type="text"],
input[type="email"],
input[type="password"] {
    border: none;
    border-bottom: 1px solid #fff;
    background: transparent;
    outline: none;
    height: 40px;
    color: #fff;
    font-size: 16px;
}

.register input[type="submit"] {
    border: none;
    outline: none;
    height: 40px;
    background: #fb4125a0;
    color: aliceblue;
    font-size: 16px;
    border-radius: 20px;
}

.register input[type="submit"]:hover {
    cursor: pointer;
    background: rgba(218, 51, 32, 0.733);
    color: #000;
}

.register a {
    text-decoration: none;
    font-size: 15px;
    line-height: 10px;
    color: rgb(255, 196, 196);
}

.register a:hover {
    color: rgb(243, 82, 64);
}

@media screen and(max-width:980px) {
    .register {
        height: 400px;
        width: 100px;
    }
}

@media screen and(max-width:380px) {
    .register {
        height: 400px;
        width: 100px;
    }
}
::placeholder{
    color: white;
}
.input-bar::placeholder{
    color:black;
}

.input-bar{
    padding:8px 15px;
    border-radius: 10px;
}
/************************* BUY CAR PAGE ****************************/
.buy-car{
    position: relative;
    top: 80px;
    display: block;
    margin: auto;
    width: 100%;
    height: 180vh;
    /* border: 5px solid red; */
}
.buy-car .filters{
    float: left;
    width: 20%;
    height: auto;
    padding: 20px;
    padding-left:60px;
    /* border: 3px solid blue; */
}
.buy-car .filters h3{
    font-size: 30px;
}
.buy-car .filters h4{
    font-size: 25px;
}
.buy-car .filters .brands{
    padding: 10px;
    margin: 30px 0px;
    /* border: 1px solid red; */
}
.buy-car .filters .years{
    padding: 10px;
    margin: 30px 0px;
    /* border: 1px solid red; */
}
.buy-car .filters .colors{
    padding: 10px;
    margin: 10px 0px;
    /* border: 1px solid red; */
}
.buy-car .filters .mileage{
    padding: 10px;
    margin: 10px 0px;
    /* border: 1px solid red; */
}
.buy-car .filters .input, 
.buy-car .filters label{
    font-size: 18px;
    padding: 10px;
}
.buy-car .cars{
    float: right;
    width: 80%;
    /* border: 7px solid green; */
}


/************************* BUY ONE CAR PAGE ****************************/
.buyone-car{
    position: relative;
    top: 80px;
    display: block;
    margin: auto;
    width: 100%;
    height: 115vh;
    /* border: 5px solid red; */
}
.buyone-car .cars{
    width: 100%;
    /* border: 7px solid green; */
}
.buyone-car .cars .heading{
    width: 60%;
    text-align: center;
    margin: 0px auto; 
    padding: 20px;
}
.buyone-car .cars .heading h1{
    padding-top: 20px;
    font-size: 42px;
    color: #c22f27;
}
.buyone-car .cars .car-section{
    display: block;
}
.buyone-car .cars .car-section .content{
    float: left;
    width: 100%;
    /* border: 1px solid red; */
}
.buyone-car .cars .car-section .one-car{
    float: right;
    width: 60%;
}
.buyone-car .cars .one-car{
    position: relative;
    top: -350px;
    width: 100%;
    height: 30vh;
} 
.buyone-car table, th, td {
    border: 1px solid black;
}
.buyone-car th,
.buyone-car td{
    padding: 5px;
}


/************************ OEM PAGE *******************/
.oem-cars{
    position: relative;
    top: 7
    0px;
}
.oem-cars .container{
    width: 100%;
    min-height: 100vh;
    background-color: #f7f7f7;
}
.oem-cars .container .car-section{
    position: absolute;
    top: 20%;
    left: 15%;
    width: 80%;
    margin: 0px auto;
}
.oem-cars .container .car-section .heading{
    width: 60%;
    text-align: center;
    margin: 18px 170px;
    /* border: 1px solid red; */
}
.oem-cars .container .car-section .heading h1{
    font-size: 45px;
    color: #c22f27;
    /* border: 1px solid red; */
}
.oem-cars .container .car-section .heading p{
    margin-top: 10px;
    color: #6e6e6e;
    font-size: 17px;
    line-height: 1.5;
}
.oem-cars .container .car-section .content{
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0px auto; */
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 3px;
}
.oem-specs{
    width: 170%;
}
.oem-specs th,td{
    padding: 10px;
}
.oem-specs td{
    text-align: center;
}
.oem-specs .buy-btn{
    padding: 5px;
    margin: 10px 20px;
    width: 80px;
    border-radius: 5px;
    color: #000;
    background-color: #19d03e;
}

/********************** DEALER SELLCAR PAGE *******************/
.dealer-sellcar{
    position: relative;
    height: 100%;
    width: 100%;
    padding: 40px 100px 100px;
    overflow-y: scroll;
    /* border: 3px solid blue; */
}
.dealer-sellcar .content{
    width: 100%;
    padding: 50px;
}
.dealer-sellcar h2{
    text-align: center;
    margin-bottom: 16px;
    font-size: 50px;
}
.dealer-sellcar .button-group{
    padding: 20px;
    /* border: 1px solid green; */
}
.dealer-sellcar .dealer-btn{
    padding: 10px;
    margin: 10px 20px;
    width: 100px;
    border-radius: 5px;
    color: #000;
    background-color: #d53232;
    /* border: 1px solid #19d03e; */
}
.dealer-sellcar .content form{
    padding: 10px;
    /* border: 1px solid #19d03e; */
}
.dealer-sellcar .content form div{
    margin-bottom: 30px;
}
.dealer-sellcar .content form label{
    display: block;
    font-size: 22px;
    margin-bottom: 10px;
}
.dealer-sellcar .content form input{
    padding: 10px;
    width: 100%;
} 
.dealer-sellcar .content form button{
    position: relative;
    top: -3px;
    padding: 10px;
    margin: 10px 20px;
    width: 100px;
    border-radius: 5px;
    color: #000;
    background-color: #d53232;   
}

/********************** Dealer Cars ***********************8*/
table.dealer-cars{
    width: 100%;
    border-collapse: collapse;
    font-size: 1.1rem;
}
.dealer-cars th, td{
    padding: 15px;
    text-align: left;
}
.dealer-cars td img{
    width: 50px;
    height: 50px;
}
.dealer-cars .edit{
    color: green;
}
.dealer-cars .delete{
    color: red;
}   
.dealer-cars .edit:hover, 
.dealer-cars .delete:hover
{
    text-decoration: underline;
}
